import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import image from '../../Images/Group1686558039.png'
import { Link } from "react-router-dom";
import xmark from "../../Images/x-mark.png";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button className="search-btn " onClick={handleOpen}>Accept</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis">
        <img src={xmark} alt="" className="x-mark m-3 position-absolute right-0" onClick={handleClose} />{" "}
          <Typography id="modal-modal-title" variant="h6" component="h2" className='are-you-sure-heading p-3'>
          Are you sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ m: 2 }}>
            <center>
            <img src={image} alt="" />
            </center>
           <p className='modal-description-p'>Are you sure you want to  reject this invite? </p>
          </Typography>
          <div className="pt-5 pb-5 m-auto mx-4">
              <button className="save-draft-btn mt-4">Reject</button>
              <Link to="/message-send">
                <button className="search-btn mt-4">Accept</button>
              </Link>
            </div>
        </Box>
      </Modal>
    </div>
  );
}
