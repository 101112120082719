import React from "react";
import girl from '../../Images/girl.png';
import animationline from '../../Images/animation-line.png';
import animationrating from '../../Images/animation-rating.png';
import animationdone from '../../Images/animation-done.png';
import animationvisitor from '../../Images/animation-visitor.png';
import animationicon from '../../Images/animation-icon.png';
import animation from '../../Images/animation.png';
import WhyChoose from './WhyChoose';
import TrustedUs from './TrustedUs';
import ReadOurBlog from './ReadOurBlog';
import CustomerSaying from './CustomerSaying';
import Subscribe from './Subscribe';

function index() {
  return (
    <div>
      <div className="container-fluid landing-section mb-5">
        
        <div className="container">
        <div className="row pt-5">
          <div className="col-md-5 build-business pt-5">
            <h2 className="pt-2">We help you to build your Business</h2>
            <p className="pt-2">
              Enthusiastically extend extensive customer service before
              best-of-breed convergence completely.
            </p>
            <button className="logout-btn mt-4">Get a Quotes</button>
          </div>
          <div className="col-md-7">
            <img src={girl} alt="" className="animtion-girl" />
            <div className="">
            <img src={animationline} alt="" className="animation-line" id="animation-none" />
            <img src={animationrating} alt="" className="animation-rating" id="animation-none" />
            <img src={animationdone} alt="" className="animation-done" id="animation-none" />
            <img src={animationvisitor} alt="" className="animation-visitor" id="animation-none" />
            <img src={animationicon} alt="" className="animation-icon" id="animation-none" />
            <img src={animation} alt="" className="animation" id="animation-none" />
            </div>
          </div>
        </div>
        </div>
      </div>
      <WhyChoose />
      <TrustedUs />
      <ReadOurBlog />
      <CustomerSaying />
      <Subscribe />
    </div>
  );
}

export default index;
