import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "../../Images/Avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Overview from "./Overview";
import Wallet from "./Wallet/index";
import DraftList from "./DraftList/index";
import ContactUs from "./ContactUs/index";
import AllContracts from "./AllContracts/index";
import AllDisputes from "./AllDisputes/index";
import TransectionHistory from "./TransectionHistory/index";
import MyEarning from "./MyEarning/index";
import Logout from "./Logout/index";
import Identificationn from "./Identification/index";
import YourImage from '../../Images/user.png';
import profile2 from '../../Images/profile-2.png';
import profile3 from '../../Images/profile-3.png';
import profile4 from '../../Images/profile-4.png';
import profile5 from '../../Images/profile-5.png';
import profile6 from '../../Images/profile-6.png';
import profile7 from '../../Images/profile-7.png';
import profile8 from '../../Images/profile-8.png';
import profile9 from '../../Images/profile-9.png';
import { AccountCircle } from '@mui/icons-material'; // Importing AccountCircle icon from Material-UI icons

function CustomImage() {
  return <img src={YourImage} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage2() {
  return <img src={profile2} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage3() {
  return <img src={profile3} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage4() {
  return <img src={profile4} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage5() {
  return <img src={profile5} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage6() {
  return <img src={profile6} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage7() {
  return <img src={profile7} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage8() {
  return <img src={profile8} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage9() {
  return <img src={profile9} alt="Your Alt Text" className="me-2 image-inline" />;
}


const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const VerticalTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div className="container">
      <div className="row mt-5 mb-5">
        <div className="col-lg-3 side-nav">
          <div className="inner-shadow pt-5 p-2">
            <center>
              <img src={Avatar} alt="" className="side-bar-profile" />
            </center>
           
            <div className="side-bar-name pb-3">
              <h2>Eureka Seken</h2>
              <p className="pb-2">eureka88@email.com</p>
              <Identificationn />
            </div>

            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 0, borderColor: "divider" }}
            >
              <Tab label="My Account" icon={<CustomImage />} {...a11yProps(0)} className="tabs-tab" />
              <Tab label="Wallet" icon={<CustomImage2 />} {...a11yProps(1)} className="tabs-tab" />
              <Tab label="Draft List" icon={<CustomImage3 />} {...a11yProps(2)} className="tabs-tab" />
              <Tab label="Contact us" icon={<CustomImage4 />} {...a11yProps(3)} className="tabs-tab" />
              <Tab label="All Contracts" icon={<CustomImage5 />} {...a11yProps(4)} className="tabs-tab" />
              <Tab label="All Disputes" icon={<CustomImage6 />} {...a11yProps(5)} className="tabs-tab" />
              <Tab label="Transaction History" icon={<CustomImage7 />} {...a11yProps(6)} className="tabs-tab" />
              <Tab label="Earnings" icon={<CustomImage8 />} {...a11yProps(7)} className="tabs-tab" />
              <Logout />
            </Tabs>
          </div>
        </div>
        <div className="col-lg-9 p-0">
          <TabPanel value={value} index={0} sx={{ Padding: "0px" }}>
            <Overview />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Wallet />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DraftList />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ContactUs />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <AllContracts />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <AllDisputes />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <TransectionHistory />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <MyEarning />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default VerticalTabs;
