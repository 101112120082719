import React from 'react'
import whychooseicon1 from '../../Images/why-choose-icon-1.png';
import whychooseicon2 from '../../Images/why-choose-icon-2.png';
import whychooseicon3 from '../../Images/why-choose-icon-3.png';
function WhyChoose() {
  return (
    <div>
      <div className="container why-choose">
        <h5 className='why-choose-h5'>Why Choose Ask Human!</h5>

        <div className="row pb-5 pt-2">

          <div className="col-lg-4 col-md-6 mt-4">
            <div className="why-choose-card p-4">
              <img src={whychooseicon1} alt="" />
              <h5 className='mt-2'>Lorem text</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the dummy  text .</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-4 mt-4">
          <div className="why-choose-card p-4">
              <img src={whychooseicon2} alt="" />
              <h5 className='mt-2'>Lorem text</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the dummy  text .</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-4 mt-4">
          <div className="why-choose-card p-4">
              <img src={whychooseicon3} alt="" />
              <h5 className='mt-2'>Lorem text</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the dummy  text .</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChoose