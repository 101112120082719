import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
import Walletimage from "../../../Images/wepik-export-20231110071244qySj1.png";

function Overview() {
  const [showSectionOne, setShowSectionOne] = useState(true);
  const [showSectionTwo, setShowSectionTwo] = useState(false);

  const handleNextClick = () => {
    setShowSectionOne(false);
    setShowSectionTwo(true);
  };

  return (
    <div className="row px-3">
      <div className="inner-shadow">
        {showSectionOne && (
          <div
            className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mb-5 mt-4"
            id="wallet-section-one"
          >
            <center>
              <img src={Walletimage} alt="" />
            </center>
            <h3 className="overview-heading mt-4">Add Payment in Wallet</h3>
            <p className="mx-5">
              Be authentic. Post Reels. Target your ideal audience. Sacrifice a
              goat. Maybe those things got you noticed last year but not
              anymore.
            </p>
            <button
              id="next"
              onClick={handleNextClick}
              className="payment-btn mt-4"
            >
              Next
            </button>
          </div>
        )}

        {showSectionTwo && (
          <div
            className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mb-5 mt-4"
            id="wallet-section-two"
          >
            <h3 className="overview-heading-wallet">Wallet</h3>
            <center>
              <img src={Walletimage} alt="" />
            </center>
            <h3 className="overview-heading mt-4">Balance</h3>
            <p className="mx-5">Your Balance in Wallet is $1000.00</p>

            <center>
              <button className="pay-now-btn mt-4">Pay now</button>
              <br />
              <button className="payment-btn mt-4">
                Add payment in Wallet
              </button>
            </center>
          </div>
        )}
      </div>
    </div>
  );
}

export default Overview;
