import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import image from '../../Images/Group1686558039.png';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import OTPDigitt from '../Login/OTP-Verification/otpdigit';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <div>
      <button className="search-btn w-100 mt-3" onClick={handleOpen}>Sent code</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis p-4">
          <h5 className='text-center'>Enter OTP</h5>

          <div className="ask-to-sign login-right-section-flex px-5 mt-5">
              
              <OTPDigitt />
   <button className='sign-in-btn'>Verify</button>
              <p className='otp-span mt-4'>Didn’t recieve OTP yet?</p>
              <p className='otp-span'><Link to="" className='forgot-password'>Resend OTP</Link></p>
              <p className='otp-span'>in 1:59 min</p>

             



            </div>
        </Box>
      </Modal>
    </div>
  );
}
