import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image from '../../../Images/cat-five1.png';
import DeleteDraft from '../../../Images/delete-draft.png';

function Overview() {

 

  return (
    <div className="row px-3">
      <div className="inner-shadow pb-5">
      <h3 className="overview-heading-wallet text-center mt-4">
        Draft list
      </h3>
      <div className="draft-driver p-4 mt-4">
        <img src={DeleteDraft} alt="" className="delete-draft" />
        <p>Lorem ipsum dolor sit amet, consectetur scing elit ut aliquam, purus sit amet.Lorem ipsum dolor sit amet, consectetur scing elit ut aliquam, purus sit amet.</p>
       
              <button className="pay-now-btn mt-4">Edit</button>
              <button className="payment-btn mt-4 float-right">
              Share
              </button>
          
      </div>
      <div className="draft-driver p-4 mt-4">
      <img src={DeleteDraft} alt="" className="delete-draft" />
      <img src={image} alt="" className="chat-media mx-3" />
       
              <button className="pay-now-btn mt-4">Edit</button>
              <button className="payment-btn mt-4 float-right">
              Share
              </button>
          
      </div>
      <div className="draft-driver p-4 mt-4">
      <img src={DeleteDraft} alt="" className="delete-draft" />
      <p className="pb-2">Lorem ipsum dolor sit amet, consectetur scing elit ut aliquam, purus sit amet.Lorem ipsum dolor sit amet, consectetur scing elit ut aliquam, purus sit amet.</p>
      <img src={image} alt="" className="chat-media mx-3" />
      
       
              <button className="pay-now-btn mt-4">Edit</button>
              <button className="payment-btn mt-4 float-right">
              Share
              </button>
          
      </div>
      </div>

      

    </div>
  );
}

export default Overview;
