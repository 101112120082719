import {Routes, Route ,useLocation } from "react-router-dom";

import Home from "./component/Home-Section/";
import WhyChoose from "./component/Home-Section/WhyChoose";
import TrustedUs from "./component/Home-Section/TrustedUs";
import ReadOurBlog from "./component/Home-Section/ReadOurBlog";
import CustomerSaying from "./component/Home-Section/CustomerSaying";
import Subscribe from "./component/Home-Section/Subscribe";
import GotoHomeModal from "./component/Home-Section/GotoHomeModal";

import Navbar from "./component/Navbar";
import Footer from "./component/Footer";

// Login 
import Login from "./component/Login/index";
import Forgot from "./component/Login/Forgot-Passsword/index";
import OTPVerification from "./component/Login/OTP-Verification/index";
import OTPDigit from "./component/Login/OTP-Verification/otpdigit";
import SignUp from "./component/Login/Sign-Up/index";
import Createprofile from "./component/Login/Create-profile/index";
import Range from "./component/Login/Create-profile/range";

// SearchNote
import SearchNote from "./component/Search-Note/index";
import Userslist from "./component/Search-Note/Userslist";
import HomeCard from "./component/Search-Note/HomeCard";
import UserDetail from "./component/Search-Note/UserDetail";
import Message from "./component/Search-Note/Message";
import Modal from "./component/Search-Note/modal";
import MessageSend from "./component/Search-Note/MessageSend";
import ContinueChatModal from "./component/Search-Note/ContinueChatModal";
import Profile from "./component/Profile/index";
import Overview from "./component/Profile/Overview";
import EditProfileModal from "./component/Profile/EditProfileModal";
import ChangePasswordModal from "./component/Profile/ChangePasswordModal";
import ChangePhoneModal from "./component/Profile/ChangePhoneModal";
import EnterOTP from "./component/Profile/EnterOTP";

// Profile-Wallet
import Wallet from "./component/Profile/Wallet/index";

// Draft List
import DraftList from "./component/Profile/DraftList/index";

// Contact Us
import ContactUs from "./component/Profile/ContactUs/index";

// All Contracts
import AllContracts from "./component/Profile/AllContracts/index";
import NavSection from "./component/Profile/AllContracts/NavSection";
import ViewContract from "./component/Profile/AllContracts/ViewContract";
import Review from "./component/Profile/AllContracts/Review";
import AddDispute from "./component/Profile/AllContracts/AddDispute";

// All Disputes
import AllDisputes from "./component/Profile/AllDisputes/index";
import NavSectionDisputes from "./component/Profile/AllDisputes/NavSection";

// Transection History
import TransectionHistory from "./component/Profile/TransectionHistory/index";

// My Earning
import MyEarning from "./component/Profile/MyEarning/index";
import Chart from "./component/Profile/MyEarning/chart";
import WithdrawAmountModal from "./component/Profile/MyEarning/WithdrawAmountModal";
import RequestSuccessfullyModal from "./component/Profile/MyEarning/RequestSuccessfullyModal";

// Logout 

import Logout from "./component/Profile/Logout/index";

// Identification 
import Identification from "./component/Profile/Identification/index";
import UploadModal from "./component/Profile/Identification/UploadModal";
import ReviewingVideo from "./component/Profile/Identification/ReviewingVideo";






function App() {
  const location = useLocation();
  console.log(location)
  const loginPaths = ['/login', '/otp-verification', '/forgot-passsword', '/sign-up', '/create-profile'];
  const isLoginPage = loginPaths.includes(location.pathname);
  
  console.log(location.pathname)

  return (
    <>
     {!isLoginPage && <Navbar />}

    <Routes >
      <Route path="/" element={ <Home /> } />
      <Route path="/" element={ <WhyChoose /> } />
      <Route path="/" element={ <TrustedUs /> } />
      <Route path="/" element={ <ReadOurBlog /> } />
      <Route path="/" element={ <CustomerSaying /> } />
      <Route path="/" element={ <Subscribe /> } />
      <Route path="/" element={ <GotoHomeModal /> } />

      {/* Login */}
      <Route path="/login" element={ <Login /> } />
      <Route path="/forgot-passsword" element={ <Forgot /> } />
      <Route path="/otp-verification" element={ <OTPVerification /> } />
      <Route path="/" element={ <OTPDigit /> } />
      <Route path="/sign-up" element={ <SignUp /> } />
      <Route path="/create-profile" element={ <Createprofile /> } />
      <Route path="/range" element={ <Range /> } />

      {/* SearchNote */}
      <Route path="/search-note" element={ <SearchNote /> } />
      <Route path="/user-list" element={ <Userslist /> } />
      <Route path="/home-card" element={ <HomeCard /> } />
      <Route path="/user-detail" element={ <UserDetail /> } />
      <Route path="/message" element={ <Message /> } />
      <Route path="/modal" element={ <Modal /> } />
      <Route path="/message-send" element={ <MessageSend /> } />
      <Route path="/profile" element={ <Profile /> } />
      <Route path="/overview" element={ <Overview /> } />
      <Route path="/edit-profile-modal" element={ <EditProfileModal /> } />
      <Route path="/" element={ <ChangePasswordModal /> } />
      <Route path="/" element={ <ChangePhoneModal /> } />
      <Route path="/" element={ <EnterOTP /> } />

      {/* Profile-Wallet */}
      <Route path="/" element={ <Wallet /> } />

      {/* Draft List */}
      <Route path="/" element={ <DraftList /> } />

      {/* Contact Us */}
      <Route path="/contact" element={ <ContactUs /> } />

      {/* All Contracts */}
      <Route path="/" element={ <AllContracts /> } />
      <Route path="/" element={ <NavSection /> } />
      <Route path="/view-contract" element={ <ViewContract /> } />
      <Route path="/" element={ <Review /> } />
      <Route path="/" element={ <AddDispute /> } />

      {/* All Disputes */}
      <Route path="/" element={ <AllDisputes /> } />
      <Route path="/" element={ <NavSectionDisputes /> } />

      {/* Transection History */}
      <Route path="/" element={ <TransectionHistory /> } />

      {/* Transection History */}
      <Route path="/" element={ <MyEarning /> } />
      <Route path="/" element={ <Chart /> } />
      <Route path="/" element={ <WithdrawAmountModal /> } />
      <Route path="/" element={ <RequestSuccessfullyModal /> } />

      {/* Logout */}
      <Route path="/" element={ <Logout /> } />

      {/* Identification */}
      <Route path="/" element={ <Identification /> } />
      <Route path="/" element={ <UploadModal /> } />
      <Route path="/" element={ <ReviewingVideo  /> } />
      

      
      

      
      
      
    </Routes>
    {/* <Footer />   */}
    {!isLoginPage && <Footer />}
    </>
  );
}

export default App;
