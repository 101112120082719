import React from 'react'
import logo from '../../Images/logo-mini.png';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { Link } from 'react-router-dom';




function index() {
    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
      });
  return (
    <div>
        <div className="container-fluid background-main pb-5">
            <h2 className='search-note-h2 pt-5'>Search Note</h2>
            <div className="search-note m-auto mt-4">
                <center>
                <img src={logo} alt="" className='pt-2' />
                <p className='robert-p pt-2'>Hi, Robert 👋</p>
              
                </center>
                <div className="px-4">
                <FloatingLabel controlId="floatingTextarea2" label="Write your note ...">
                    <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: '100px' }}
                    />
                </FloatingLabel>
                <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                // startIcon={<CloudUploadIcon />}
                className="upload-file-photo mt-3"
              >
                <FontAwesomeIcon
                  icon={faPhotoFilm}
                  className="upload-file-icon-photo"
                />
                Add Photos
                <VisuallyHiddenInput type="file" />
              </Button>
              <div className="mt-4 pb-5 m-auto">
              
                <button className='save-draft-btn mt-4'>Save Draft</button>
                <Link to="/user-list"><button className='search-btn mt-4'>Search</button></Link>
             

              </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default index