import React from 'react'
import trustedus from '../../Images/trusted-us.png';
import trustedus1 from '../../Images/trusted-us-1.png';

function TrustedUs() {
  return (
    <div>
      <div className="container-fluid trusted-us">
        <div className="container pt-5 pb-5">
        <div className="row">
        <div className="col-md-6">
          <img src={trustedus} alt="" className='trusted-us-image' />
        </div>
        <div className="col-md-6">
          <h2 className='why-choose-h5 text-start pt-5'>Lorem Ipsum text Heading</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book. It has survived not  only five centuries, but also the leap into electronic typesetting,  remaining essentially unchanged.</p>
        </div>

        <div className="col-md-6">
          <h2 className='why-choose-h5 text-start pt-5'>Lorem Ipsum text Heading</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book. It has survived not  only five centuries, but also the leap into electronic typesetting,  remaining essentially unchanged.</p>
        </div>
        <div className="col-md-6">
          <img src={trustedus1} alt="" className='trusted-us-image' />
        </div>
        


        </div>
        </div>
      </div>
    </div>
  )
}

export default TrustedUs