import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
import Added from '../../../Images/Added.png';
import withdrawal from '../../../Images/withdrawal.png';
import Chart from './chart';
import WithdrawAmountModal from './WithdrawAmountModal';
import fillter from '../../../Images/fillter.svg';

function Overview() {

  return (
    <div className="row px-3">
      <div className="inner-shadow">
       

          <div
            className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mb-5 mt-4"
            id="wallet-section-two"
          >
            <h3 className="overview-heading-wallet">My Earning</h3>
           <div className="inner-shadow text-start p-3 mt-4">
            <p>Wallet Balance</p>
            <h3 className="overview-heading-wallet text-start">$59.90</h3>
           
            <WithdrawAmountModal />
           </div>
          
             
            
          <Chart />
          </div>
      </div>
    </div>
  );
}

export default Overview;
