import React from "react";
import logo from "../../Images/logo-mini.png";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import fillter from '../../Images/fillter.svg';
import HomeCard from './HomeCard';
import { experimentalStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


function index() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <div>
      <div className="container-fluid background-main pb-5">
        <div className="container">
          <h2 className="search-note-h2 pt-5">All Users list</h2>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <Link underline="hover" color="inherit" href="/">
                Home
              </Link>

              <Typography color="text.primary">
                {" "}
                <b>User listing</b>
              </Typography>
            </Breadcrumbs>
          </div>
          <div className="row position-relative">
            <div className="search-bar d-flex mt-4">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="p-3" />
              <input type="text" placeholder="Search..." />
            </div>
         
            <div className="search-bar search-bar-right d-flex mt-4 float-end ">
              <p className="p-3">Filter by</p><img src={fillter} alt="" className="float-end position-absolute right-5 top-5" />
            </div>

          </div>
        

          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} className="mt-4">
                {Array.from(Array(10)).map((_, index) => (
                  <Grid item xs={12} sm={4} md={2.4} key={index}>
                  
                    <HomeCard />
                
                  </Grid>
                ))}
          </Grid>

          <div className="mt-4 pb-5 m-auto">
              
              <button className='save-draft-btn mt-4'>Close</button>
              <Link to="/user-list"><button className='search-btn mt-4'>Sent Invitation</button></Link>
           

            </div>
   
        </div>
      </div>
    </div>
  );
}

export default index;
