import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
import image from '../../../Images/transformed-(1) 1.png';

function Overview() {

  return (
    <div className="row px-3">
      <div className="inner-shadow">
       

          <div
            className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mb-5 mt-4"
            id="wallet-section-two"
          >
            <h3 className="overview-heading-wallet">Contact us</h3>
            <center>
              <img src={image} alt="" />
            </center>
            <h3 className="overview-heading mt-4">Mail Us</h3>
            <p className="mx-5">Write To Us On</p>
            <h5 className="overview-heading-h5">www.fyloexample@gmail.com</h5>

            <center>
              <button className="payment-btn mt-5">
                Contact us
              </button>
            </center>
          </div>
      </div>
    </div>
  );
}

export default Overview;
