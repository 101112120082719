import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
// import image from '../../../Images/transformed-(1) 1.png';
import NavSection from '../AllDisputes/NavSection';

function Overview() {

  return (
    <div className="row px-3">
      <div className="inner-shadow">
       

          <div
            className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mb-5 mt-4"
            id="wallet-section-two"
          >
            <h3 className="overview-heading-wallet">All Disputes</h3>
           
           <div className="row">
            <NavSection />
           </div>
          </div>
      </div>
    </div>
  );
}

export default Overview;
