import { Box, Button, Card, Grid, styled, TextField } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
const token = localStorage.getItem("token");

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: 32,
  background: theme.palette.background.default,
}));

const ForgotPasswordOTPVerify = ({ email }) => {
  const navigate = useNavigate();
  const inputRefs = useRef(Array.from({ length: 6 }, () => React.createRef()));

  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);

  const handleInputChange = (index, value, isBackOrDelete) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);

    if (isBackOrDelete && value === "" && index > 0) {
      // Move to the previous input if the current input is empty
      inputRefs.current[index - 1].current.focus();
    } else if (index < inputRefs.current.length - 1 && value !== "") {
      // Move to the next input if the current input is not the last one
      inputRefs.current[index + 1].current.focus();
    }
  };

  return (
    <ContentBox>
      <Box mb={3}>
        <Grid container spacing={1} justifyContent="center">
          {inputRefs.current.map((ref, index) => (
            <Grid item key={index}>
              <input
                type="text"
                className={`otp__digit otp__field__${index + 1}`}
                id="input-outline"
                inputMode="numeric"
                maxLength={1}
                ref={ref}
                value={otpInputs[index]}
                onChange={(e) => handleInputChange(index, e.target.value)}
                pattern="[0-9]+"
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/[^0-9]/g, "")
                    .replace(/(\..*)\./g, "$1");
                }}
                style={{
                  height: "55px",
                  width: "55px",
                  textAlign: "center",
                  fontSize: "20px",
                  border: "1px solid #DBDBDB",
                  borderRadius: "5px",
                }}
                onKeyDown={(e) => {
                  const isBackspace = e.keyCode === 8;
                  const isDelete = e.keyCode === 46;
                  if (isBackspace || isDelete) {
                    const isBackOrDelete = isBackspace || isDelete;
                    handleInputChange(index, e.target.value, isBackOrDelete);
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ContentBox>
  );
};

export default ForgotPasswordOTPVerify;
