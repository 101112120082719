import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import image from '../../Images/Group1686558039.png';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Range from "../Login/Create-profile/range";
import xmark from "../../Images/x-mark.png";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  
  const [open, setOpen] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <div>
      <FontAwesomeIcon icon={faPen} onClick={handleOpen} style={{cursor:"pointer"}} />
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis p-4">
        <img src={xmark} alt="" className="x-mark" onClick={handleClose} />{" "}
          <h5 className='text-center'>Edit Profile</h5>

          

          <div className="ask-to-sign ask-to-sign-scroll px-5  ">
          <Form.Label htmlFor="inputPassword5" className="form-label mt-5">
                Upload Profile
              </Form.Label>{" "}
              <br />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                // startIcon={<CloudUploadIcon />}
                className="upload-file mt-2"
              >
                <FontAwesomeIcon
                  icon={faPhotoFilm}
                  className="upload-file-icon"
                />
                <VisuallyHiddenInput type="file" />
              </Button>
              <br />
            <div className="row">
            
              <div className="col-md-6">
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                User name
              </Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="form-input mt-1"
              />

<Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Ethnicity
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Empty</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>

              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Age
              </Form.Label>
              <Range />

              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Do you Smoke ?
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">input</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>

              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Do you Workout ?
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Yoga</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>
              


              </div>


              <div className="col-md-6">
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Gender
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Male</option>
                <option value="2">Female</option>
                <option value="3">Other</option>
              </Form.Select>

              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Zodiac
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Empty</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>

              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Hourly price
              </Form.Label>
              <InputGroup className=" mt-1">
                <InputGroup.Text className="b-none">$</InputGroup.Text>
                <Form.Control aria-label="Amount (to the nearest dollar)" />
                <InputGroup.Text>/hr</InputGroup.Text>
              </InputGroup>

              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Do you Drink ?
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Socially</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>

              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Bodytype
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Slim</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>

              </div>
            </div>
             
              
              
              
              
              
              
              
              
              
              
              
              
            </div>
            
          <button className="search-btn w-100 mt-4 ">Edit profile</button>
        </Box>
      </Modal>
    </div>
  );
}
