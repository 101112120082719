import React from "react";
import logo from "../Images/footer-logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import 'aos/dist/aos.css';
function Footer() {
  return (
    <footer>
      <div className="footer-main pb-2">
        <div className="container-fluid">
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <a href="#" className="footer_logo">
                      <img src={logo} alt="" />
                    </a>
                    <p className="footer-lorem">Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy  text ever since the 1500s.</p>
                  </div>
                  
                </div>
              </div>
            
                  <div className="col-lg-3 col-md-6 about_ft">
                    <h5 className="about_h5 ">Link</h5>
                    <ul className="about_us_ul">
                      <li>
                        <Link
                          // to={}
                          className="link_decorations_white"
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/about_us"}
                          className="link_decorations_white"
                        >
                          View profile
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to={"/privacypolicy"}
                          className="link_decorations_white"
                        >
                          Contact us
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to={"/terms_condition"}
                          className="link_decorations_white"
                        >
                          Search notes
                        </Link>
                      </li>
                   
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 about_ft">
                    <h5 className="about_h5 ">Company</h5>
                    

                    <ul className="about_us_ul">
                      <li>Privacy Policy</li>
                        <li>Terms & conditions</li>
                    
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 about_ft">
                    <h5 className="about_h5 ">Subscribe</h5>
                    <ul className="about_us_ul">
                   <li><b>Email:</b> test@gmail.com</li>
                        <li><b>Address:</b> Akshya Nagar 1st Block 1st  Cross, Rammurthy nagar,  Bangalore-560016</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <hr />
          
          
             <p className="copy_right">
             Copyrightagency. All right reserves
                </p>
          
             </div>
       
            
          </div>
       

        
    </footer>
  );
}
export default Footer;
