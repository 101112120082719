import React from "react";
import logo from "../../Images/logo-mini.png";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import fillter from "../../Images/fillter.svg";
import HomeCard from "./HomeCard";
import { experimentalStyled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import profile from "../../Images/Ellipse1.png";
import verified from "../../Images/verified.png";
import profileimg from "../../Images/Rectangle75.png";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";

function index() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <div>
      <div className="container-fluid background-main pb-5">
        <div className="container">
          <h2 className="search-note-h2 pt-5">All Users list</h2>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <Link underline="hover" color="inherit" href="/" className="">
                Home
              </Link>
              <Link underline="hover" color="inherit" href="/">
                User listing
              </Link>

              <Typography color="text.primary">
                {" "}
                <b>User Detail</b>
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row mt-5">
            <div className="d-flex">
              <img src={profile} alt="" className="user-profile" />
              <span>
                {" "}
                <h3 className="user-name d-flex mt-4 ms-4">
                  John Deo{" "}
                  <span>
                    {" "}
                    <img src={verified} alt="" className="mt-1 ms-2" />
                  </span>
                </h3>
                <Link to="/message">
                <button className="search-btn-invitation ms-3 mt-2">
                  Sent Invitation
                </button>
                </Link>
              </span>
            </div>
          </div>

          <div className="row mt-5">
            <h3 className="user-name">Description</h3>
            <p className="user-description">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard text ever
              since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. Lorem Ipsum is simply
              dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard text ever since the 1500s, when
              an unknown printer took a galley of type and scrambled it to make
              a type specimen book. Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard text ever since the 1500s, when an unknown
              printer took a galley of type and scrambled it to make a type
              specimen book. Lorem Ipsum is simply dummy text of the printing
              and typesetting industry. Lorem Ipsum has been the industry's
              standard text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book.
            </p>
          </div>

          <div className="row mt-5">
            <h3 className="user-name">
              Review (4.5) <FontAwesomeIcon icon={faStar} color="#FFA115" />
            </h3>
            <div className="d-flex mt-4">
              <span>
                {" "}
                <img src={profileimg} alt="" />
              </span>
              <span>
                {" "}
                <Rating
                  name="half-rating-read"
                  defaultValue={2.5}
                  precision={0.5}
                  readOnly
                  className="mt-2 ms-2"
                />
              </span>
            </div>
            <p className="user-description-rating pt-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard.
            </p>
          </div>
          <hr />
          <div className="d-flex mt-4">
            <span>
              {" "}
              <img src={profileimg} alt="" />
            </span>
            <span>
              {" "}
              <Rating
                name="half-rating-read"
                defaultValue={2.5}
                precision={0.5}
                readOnly
                className="mt-2 ms-2"
              />
            </span>
          </div>
          <p className="user-description-rating pt-2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard.
          </p>
        </div>
      </div>
    </div>
  );
}

export default index;
