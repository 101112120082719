import React, { useState } from 'react';
import logo from '../../../Images/logo-mini.png';
import Form from 'react-bootstrap/Form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import google from '../../../Images/google.png';
import apple from '../../../Images/apple.png';
import { Link } from 'react-router-dom';

function Index() {

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#E30B83' : '#E30B83',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 login-left-section d-flex px-5">
            <div className="justify-content-center align-self-center">
              <h2>International ask Question with Ask Human!</h2>
              <p>Enter the email and password for Signin Ask Human!</p>
            </div>
          </div>
          <div className="col-md-5 login-right-section m-auto">
            <p className='p-4 login-right-section-p'>Don’t have an account? <span><b><Link to="/login" className='forgot-password text-dark'>Signin</Link></b></span></p>
            <div className="ask-to-sign px-5 ">
              <h2 className='d-flex'>Signup to Ask Human! <img src={logo} alt="" /></h2>

              <Form.Label htmlFor="inputPassword5" className='form-label mt-5'>Email</Form.Label>
              <Form.Control
                type="email"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className='form-input mt-1'
              />

<Form.Label htmlFor="inputPassword5" className='form-label mt-3'>Phone number</Form.Label>
              <Form.Control
                type="number"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className='form-input mt-1'
              />

              <Form.Label htmlFor="inputPassword5" className='form-label mt-3'>Password</Form.Label>
              <div className="password-input-container">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  className='form-input mt-1'
                />
                <span onClick={togglePasswordVisibility} className="toggle-password-icon">
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
              </div>

              <Form.Label htmlFor="inputPassword5" className='form-label mt-3'>Confirm Password</Form.Label>
              <div className="password-input-container">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  className='form-input mt-1'
                />
                <span onClick={togglePasswordVisibility} className="toggle-password-icon">
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
              </div>

             
              

              <p className='continue-with mt-5'>Or continue with</p>

              <center className="button-center d-flex mt-4">
                <button className='google-btn mx-2'><img src={google} alt="" /> {'\u00A0'}Google</button>
                <button className='google-btn mx-2'><img src={apple} alt="" />{'\u00A0'} Apple</button>
                
              </center>

              <Link to="/create-profile"><button className='sign-in-btn mt-4'>Sign up</button></Link>



            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
