import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import image from '../../Images/Group1686558039.png';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import EnterOTP from './EnterOTP';
import xmark from "../../Images/x-mark.png";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <div>
      <button className="change-phone-number-btn mt-2" onClick={handleOpen}>Change phone number</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis p-4">
        <img src={xmark} alt="" className="x-mark" onClick={handleClose} />{" "}
          <h5 className='text-center'>Change Phone Number</h5>

          <Form.Label htmlFor="inputPasswordOld" className='form-label mt-4'>Enter your phone number</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type="text"
              id="inputPasswordOld"
              aria-describedby="passwordHelpBlockOld"
              className='form-input mt-1'
              placeholder='Enter phone number'
            />
            
          </div>

          <EnterOTP />
          
        </Box>
      </Modal>
    </div>
  );
}
