import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import image from '../../Images/Group1686558039.png';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import xmark from "../../Images/x-mark.png";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <div>
      <button className="change-phone-number-btn mt-2" onClick={handleOpen}>Change password</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis p-4">
        <img src={xmark} alt="" className="x-mark" onClick={handleClose} />{" "}
          <h5 className='text-center'>Change Password</h5>

          <Form.Label htmlFor="inputPasswordOld" className='form-label mt-4'>Old Password</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type={showOldPassword ? "text" : "password"}
              id="inputPasswordOld"
              aria-describedby="passwordHelpBlockOld"
              className='form-input mt-1'
              placeholder='Enter old Password'
            />
            <span onClick={toggleOldPasswordVisibility} className="toggle-password-icon">
              {showOldPassword ? <FiEyeOff /> : <FiEye />}
            </span>
          </div>

          <Form.Label htmlFor="inputPasswordNew" className='form-label mt-4'>New Password</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type={showNewPassword ? "text" : "password"}
              id="inputPasswordNew"
              aria-describedby="passwordHelpBlockNew"
              className='form-input mt-1'
              placeholder='Enter Password'
            />
            <span onClick={toggleNewPasswordVisibility} className="toggle-password-icon">
              {showNewPassword ? <FiEyeOff /> : <FiEye />}
            </span>
          </div>

          <Form.Label htmlFor="inputPasswordConfirm" className='form-label mt-4'>Confirm Password</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type={showConfirmPassword ? "text" : "password"}
              id="inputPasswordConfirm"
              aria-describedby="passwordHelpBlockConfirm"
              className='form-input mt-1'
              placeholder='Confirm Password'
            />
            <span onClick={toggleConfirmPasswordVisibility} className="toggle-password-icon">
              {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
            </span>
          </div>

          <button className="search-btn w-100 mt-3">Confirm</button>
        </Box>
      </Modal>
    </div>
  );
}
