import React from 'react'
import LeftLine from '../../Images/right-line.png';
import AnimationSaying1 from '../../Images/animation-saying-1.png';
import AnimationSaying2 from '../../Images/animation-saying-2.png';
import AnimationSaying3 from '../../Images/animation-saying-3.png';
import AnimationSaying4 from '../../Images/animation-saying-4.png';
import AnimationSaying5 from '../../Images/animation-saying-5.png';
import AnimationSaying6 from '../../Images/animation-saying-1.png';
import RightLine from '../../Images/left-lines.png';

function CustomerSaying() {
  return (
    <div>
      <div className="container customer-saying mt-5">
        <div className="row">
        <div className="col-md-3 position-relative none-section">
          <img src={LeftLine} alt="" />
          <img src={AnimationSaying1} alt="" className='animation-saying-1' />
          <img src={AnimationSaying2} alt="" className='animation-saying-2' />
          <img src={AnimationSaying3} alt="" className='animation-saying-3' />
          
        </div>
        <div className="col-md-6 mt-5">
        <div className="customer-saying-card">
        <h2 className='why-choose-h5 pt-4'>See what our customer's saying</h2>
        <p className='customer-saying-p pt-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <h5 className='customer-saying-william text-center pt-4'>William Smith</h5>
        <p className='text-center'>Chef Director</p>
        </div>
        </div>
        <div className="col-md-3 position-relative none-section">
        <img src={RightLine} alt="" />
          <img src={AnimationSaying4} alt="" className='animation-saying-4' />
          <img src={AnimationSaying5} alt="" className='animation-saying-5' />
          <img src={AnimationSaying6} alt="" className='animation-saying-6' />
        </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerSaying