import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import xmark from "../../Images/x-mark.png";
import LightBulb from "../../Images/3d-render-hand-with-light-bulb-creative-idea.png";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button className="sign-in-btn mt-4 mb-5" onClick={handleOpen}>
        Create profile
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis p-4">
          <img src={xmark} alt="" className="x-mark" onClick={handleClose} />{" "}
          <center>
            <img src={LightBulb} alt="" />
          </center>
          <h5 className="text-center">Welcome to Ask Human </h5>
          <p className="text-center pt-2">
            Congratulation for your First step towards Getting your answers
            using chat.
          </p>
          <center className="mt-4">
            <Link to="/"><button className="logout-btn mx-2 w-100">Go to Home</button></Link>
          </center>
        </Box>
      </Modal>
    </div>
  );
}
