import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image from "../../Images/card-image.png";
// import star from "../../Images/star.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGalacticRepublic } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import AOS from 'aos';
import verified from "../../Images/verified.png";

export default function ImgMediaCard() {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  React.useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: 'ease', // default easing for AOS animations
      offset: 100, // offset (in px) from the original trigger point
      delay: 100, // delay (in ms) for AOS animations
      once: true // whether animation should happen only once
    });
  }, []);
  return (
  <div>
    
    <Card sx={{ maxWidth: 345 }} className='home-card-sw' data-aos="fade-up">
      <button className='open-now-btnn'><Checkbox {...label} defaultChecked color="secondary" style={{color:"white"}} /></button> 
      
      {/* <Checkbox {...label} className='checkbox' /> */}
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        className='card-image'
        image={image}
      />
     <Link to="/user-detail" className='link-decoration'>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div" className='card_heading d-flex'>
        Roger Federer <span className='mt-2 ms-2'><img src={verified} alt="" /></span>
        </Typography>
        <Typography variant="body2" color="text.secondary" className='card-pera'> Hey there, I am interested with you ...
        </Typography>
      
      </CardContent>
      </Link>
    </Card> 
    
  </div>
  );
}
