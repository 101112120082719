import React from 'react'
import profile from "../../../Images/Ellipse1.png";
import verified from "../../../Images/verified.png";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { List, ListItem, Popover } from "@mui/material";
import { useState } from "react";
import chatmedia from "../../../Images/cat-five1.png";
import Review from './Review';
import AddDispute from './AddDispute';



function ViewContract() {
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
      setPopoverAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setPopoverAnchorEl(null);
    };
  
    const openPopover = Boolean(popoverAnchorEl);
  
    const VisuallyHiddenInput = styled("input")({
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      height: 1,
      overflow: "hidden",
      position: "absolute",
      bottom: 0,
      left: 0,
      whiteSpace: "nowrap",
      width: 1,
    });
  
  return (
    <div>
        <div className="container-fluid background-main pb-5">
        <div className="container">
          <h2 className="search-note-h2 pt-5">Active Contracts</h2>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <Link underline="hover" color="inherit" href="/" className="">
              Contracts
              </Link>
             

              <Typography color="text.primary">
                {" "}
                <b>Active Contracts</b>
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row mt-5">
            <div className="d-flex">
              <img src={profile} alt="" className="user-profile" />
              <span>
                {" "}
                <h3 className="user-name d-flex mt-5 ms-4">
                  John Deo{" "}
                  <span>
                    {" "}
                    <img src={verified} alt="" className="mt-1 ms-2" />
                  </span>
                </h3>
                
              </span>
            </div>
            
          </div>
          <div className="bar-icon mt-2" onClick={handlePopoverOpen}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </div>
                <Popover
                  open={openPopover}
                  anchorEl={popoverAnchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <List>
                  <span><AddDispute /></span>
                    {/* <ListItem button onClick={handlePopoverClose}>
                    
                    </ListItem> */}
                    <span><Review /></span>
                    <ListItem button onClick={handlePopoverClose}>
                      End Contract
                    </ListItem>

                    
                  </List>
                </Popover>

                <div className="row mt-5 activecontract">
                    <h4>Question</h4>
                <img
                          src={chatmedia}
                          alt=""
                          className="chat-media"
                        />

                        <p className='mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard  text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard  text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                        <h4 className='mt-4'>Answer</h4>

                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard  text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard  text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>

          
       
            
              

         

  
          </div>
        </div>

        

          
        </div>
  
  )
}

export default ViewContract