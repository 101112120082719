  import * as React from "react";
  import Box from "@mui/material/Box";
  import Button from "@mui/material/Button";
  import Modal from "@mui/material/Modal";
  import { Link } from "react-router-dom";
  import Form from "react-bootstrap/Form";
  import { useState } from "react";
  import { FiEye, FiEyeOff } from "react-icons/fi";
  import Rating from "@mui/material/Rating";
  import Typography from "@mui/material/Typography";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faCircle } from "@fortawesome/free-solid-svg-icons";
  import xmark from "../../../Images/x-mark.png";
  import money1 from "../../../Images/money1.png";
  import Tabs from "@mui/material/Tabs";
  import Tab from "@mui/material/Tab";
  import Identification from "../../../Images/bag-icon.png";
  import VideoIdentification from '../../../Images/Video-Identification.png';
  import UploadModal from './UploadModal';

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  export default function BasicModal() {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
      <div>
        {/* <button className="search-btn w-100 mt-3" onClick={handleOpen}>Procced</button> */}
        <h3 onClick={handleOpen} className="identification-modal">
          <span>
            <img
              src={Identification}
              alt=""
              className="user-identification-icon image-inline me-1"
            />
          </span>
          Identification
        </h3>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="p-0 border-0 border-rduis p-4">
            <img src={xmark} alt="" className="x-mark" onClick={handleClose} />{" "}
            {/* Add onClick handler here */}
            <center>{/* <img src={money1} alt="" /> */}</center>
            <h5 className="text-center">Video Identification </h5>
            <p className="text-center pt-2 round-bold">
              Align your face in the center of the circle , Video Size is maximum 15 Sec only
            </p>
            <img src={VideoIdentification} alt="" className="video-img mt-2" />
            <p className="text-center pt-2 round-bold">
            10 Secound
            </p>
            <center className="mt-4">
              {/* <button className="cancel-btn mx-2">Cancel</button>{" "} */}
              <UploadModal />
            </center>
          </Box>
        </Modal>
      </div>
    );
  }
