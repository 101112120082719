import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
import Added from '../../../Images/Added.png';
import withdrawal from '../../../Images/withdrawal.png';

function Overview() {

  return (
    <div className="row px-3">
      <div className="inner-shadow">
       

          <div
            className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mb-5 mt-4"
            id="wallet-section-two"
          >
            <h3 className="overview-heading-wallet">Transection History</h3>
           
          <div className="row text-start">
            <div className="col-12">
              <div className="d-flex">
                <span>
                  <img src={Added} alt="" className="transection-info-icon" />
                </span>
                <span className="transection-info">
                  <h2 className="px-2 mb-0 pt-1">Added</h2>
                  <p className="p-0 px-2">Jul 12 2023 21:00</p>
                </span>
              </div>
              <span className="float-right transection-info-doller">
              $600
              </span>
            </div>
            
            <div className="col-12">
              <div className="d-flex">
                <span>
                  <img src={withdrawal} alt="" className="transection-info-icon" />
                </span>
                <span className="transection-info">
                  <h2 className="px-2 mb-0 pt-1">Withdrawal</h2>
                  <p className="p-0 px-2">Jul 12 2023 21:00</p>
                </span>
              </div>
              <span className="float-right transection-info-doller-withdrawal">
              $600
              </span>
            </div>

            <div className="col-12">
              <div className="d-flex">
                <span>
                  <img src={Added} alt="" className="transection-info-icon" />
                </span>
                <span className="transection-info">
                  <h2 className="px-2 mb-0 pt-1">Added</h2>
                  <p className="p-0 px-2">Jul 12 2023 21:00</p>
                </span>
              </div>
              <span className="float-right transection-info-doller">
              $600
              </span>
            </div>
            
            <div className="col-12">
              <div className="d-flex">
                <span>
                  <img src={withdrawal} alt="" className="transection-info-icon" />
                </span>
                <span className="transection-info">
                  <h2 className="px-2 mb-0 pt-1">Withdrawal</h2>
                  <p className="p-0 px-2">Jul 12 2023 21:00</p>
                </span>
              </div>
              <span className="float-right transection-info-doller-withdrawal">
              $600
              </span>
            </div>

            <div className="col-12">
              <div className="d-flex">
                <span>
                  <img src={Added} alt="" className="transection-info-icon" />
                </span>
                <span className="transection-info">
                  <h2 className="px-2 mb-0 pt-1">Added</h2>
                  <p className="p-0 px-2">Jul 12 2023 21:00</p>
                </span>
              </div>
              <span className="float-right transection-info-doller">
              $600
              </span>
            </div>
            
            <div className="col-12">
              <div className="d-flex">
                <span>
                  <img src={withdrawal} alt="" className="transection-info-icon" />
                </span>
                <span className="transection-info">
                  <h2 className="px-2 mb-0 pt-1">Withdrawal</h2>
                  <p className="p-0 px-2">Jul 12 2023 21:00</p>
                </span>
              </div>
              <span className="float-right transection-info-doller-withdrawal">
              $600
              </span>
            </div>

            <div className="col-12">
              <div className="d-flex">
                <span>
                  <img src={Added} alt="" className="transection-info-icon" />
                </span>
                <span className="transection-info">
                  <h2 className="px-2 mb-0 pt-1">Added</h2>
                  <p className="p-0 px-2">Jul 12 2023 21:00</p>
                </span>
              </div>
              <span className="float-right transection-info-doller">
              $600
              </span>
            </div>
            
           

          </div>
          </div>
      </div>
    </div>
  );
}

export default Overview;
