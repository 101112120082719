import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import xmark from '../../../Images/x-mark.png';
import money1 from '../../../Images/money1.png';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import profile9 from '../../../Images/profile-9.png';

function CustomImage9() {
  return <img src={profile9} alt="Your Alt Text" className="me-2 image-inline" />;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <button className="search-btn w-100 mt-3" onClick={handleOpen}>Procced</button> */}
      <Tab label="Logout" icon={<CustomImage9 />}  {...a11yProps(8)} className="tabs-tab" onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis p-4">
          <img src={xmark} alt="" className='x-mark' onClick={handleClose} /> {/* Add onClick handler here */}
          <center>
          {/* <img src={money1} alt="" /> */}
          </center>
          <h5 className='text-center'>Logout </h5>
          <p className='text-center pt-2'><b>You really want to logout your account</b></p>
        
          <center className='mt-4'>
         <button className='cancel-btn mx-2'>Cancel</button> <Link to="/login"><button className='logout-btn mx-2'>Logout</button></Link>
          </center>
        </Box>
      </Modal>
    </div>
  );
}
