import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import xmark from '../../../Images/x-mark.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button onClick={handleOpen} className='px-3'>Add Review</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis p-4">
          <img src={xmark} alt="" className='x-mark' onClick={handleClose} /> {/* Add onClick handler here */}
          <h5 className='text-center'>Review </h5>

          <Form.Label htmlFor="inputPasswordOld" className='form-label-dark mt-2'>Add review</Form.Label> <br />

          <Rating
            name="half-rating-read"
            defaultValue={2.5}
            precision={0.5}
            readOnly
            className="mt-0"
          />
          <br />

          <Form.Label htmlFor="inputPasswordOld" className='form-label-dark mt-3'>Tell us about your trip</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type="text"
              id="inputPasswordOld"
              aria-describedby="passwordHelpBlockOld"
              className='form-input mt-1'
              placeholder='Write your review here'
            />

            <button className="search-btn w-100 mt-3">Add review</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
