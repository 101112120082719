import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import profileimg from "../../../Images/Ellipse1.png";
import { Link } from "react-router-dom";

export default function LabTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // Sample data for demonstration
  const disputesData = [
    {
      contractName: "John Deo",
      disputeDate: "20 Nov 2023",
      reason: "Not get Valid answer",
      status: "In review"
    },
    {
      contractName: "Jane Smith",
      disputeDate: "15 Dec 2023",
      reason: "Late Delivery",
      status: "In progress"
    },
    {
      contractName: "Jane Smith",
      disputeDate: "15 Dec 2023",
      reason: "Late Delivery",
      status: "In progress"
    },
    {
      contractName: "Jane Smith",
      disputeDate: "15 Dec 2023",
      reason: "Late Delivery",
      status: "In progress"
    },
    
    // Add more data objects as needed
  ];

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="My Disputes" value="1" className='contracts-nav-heading' />
            <Tab label="Disputes" value="2" className='contracts-nav-heading' />
          </TabList>
        </Box>
        <TabPanel value="1">
          

            {/* Loop through disputesData array and render the section */}
            <div className="row">
            {disputesData.map((dispute, index) => (
            <div className="col-md-6 mt-3" key={index}>
              <div className="my-dispute-card text-start p-3">
                <div className="row">
                  <div className="col-4"><p className='text-dark'><b>Contract Name</b></p></div>
                  <div className="col-8"><p>{dispute.contractName}</p></div>
                </div>
                <div className="row mt-2">
                  <div className="col-4"><p className='text-dark'><b>Dispute Date</b></p></div>
                  <div className="col-8"><p>{dispute.disputeDate}</p></div>
                </div>
                <div className="row mt-2">
                  <div className="col-4"><p className='text-dark'><b>Reason</b></p></div>
                  <div className="col-8"><p>{dispute.reason}</p></div>
                </div>
                <div className="row mt-2">
                  <div className="col-4"><p className='text-dark'><b>Status</b></p></div>
                  <div className="col-8"><span className='in-review'>{dispute.status}</span></div>
                </div>
              </div>
            </div>
          ))}
            </div>
        </TabPanel>
        <TabPanel value="2">
        {/* Your content for the first tab panel */}
        </TabPanel>
      </TabContext>
    </Box>
  );
}
