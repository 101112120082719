import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import xmark from '../../../Images/x-mark.png';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button onClick={handleOpen} className='px-3 pb-2 pt-2'>Add Dispute</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis p-4">
          <img src={xmark} alt="" className='x-mark' onClick={handleClose} /> {/* Add onClick handler here */}
          <h5 className='text-center'>Add Dispute </h5>

          <Form.Label htmlFor="inputPasswordOld" className='form-label-dark mt-2'>Reason for dispute</Form.Label> <br />

          <Form.Select aria-label="Default select example" className='form-input-modal'>
      <option>Open this select menu</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>

          <Form.Label htmlFor="inputPasswordOld" className='form-label-dark mt-3'>Comment</Form.Label>
          <div className="password-input-container">
          <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px' }}
          className='form-input-modal'
        />

            <button className="search-btn w-100 mt-3">Add Dipute</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
