import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Subscribe() {
  return (
    <div>
      <div className="container subscribe-section mt-5 mb-5 p-5">
    <h2 className='text-center pt-4'>Subscribe Now for Special Features !</h2>
    <div className="subscribe-search-section mt-5 m-auto">
    <input type="text" placeholder='Enter Your Email' className='m-3' /><button className='m-2'>Submit <FontAwesomeIcon icon={faArrowRight} className='blog-card-icon' /></button>
    </div>
      </div>
    </div>
  )
}

export default Subscribe