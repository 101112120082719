import React, { useState } from "react";
import logo from "../../../Images/logo-mini.png";
import Form from "react-bootstrap/Form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import google from "../../../Images/google.png";
import apple from "../../../Images/apple.png";
import { Link } from "react-router-dom";
// import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Range from "./range";
import GotoHomeModal from '../../Home-Section/GotoHomeModal';

function Index() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#E30B83" : "#E30B83",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 login-left-section d-flex px-5">
            <div className="justify-content-center align-self-center">
              <h2>International ask Question with Ask Human!</h2>
              <p>Enter all the details for create the User Profile</p>
            </div>
          </div>
          <div className="col-md-5 login-right-section m-auto">
            <p className="p-4 login-right-section-p">
              Don’t have an account?{" "}
              <span>
                <b>
                  <Link to="/login" className="forgot-password text-dark">
                  Signin
                  </Link>
                </b>
              </span>
            </p>
            <div className="ask-to-sign px-5 ">
              <h2 className="d-flex">
                Create User Profile <img src={logo} alt="" />
              </h2>
              <Form.Label htmlFor="inputPassword5" className="form-label mt-5">
                Upload Profile
              </Form.Label>{" "}
              <br />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                // startIcon={<CloudUploadIcon />}
                className="upload-file mt-2"
              >
                <FontAwesomeIcon
                  icon={faPhotoFilm}
                  className="upload-file-icon"
                />
                <VisuallyHiddenInput type="file" />
              </Button>
              <br />
              <Form.Label htmlFor="inputPassword5" className="form-label mt-4">
                User name
              </Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="form-input mt-1"
              />
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Gender
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Male</option>
                <option value="2">Female</option>
                <option value="3">Other</option>
              </Form.Select>
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Ethnicity
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Empty</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Zodiac
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Empty</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Age
              </Form.Label>
              <Range />
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Hourly price
              </Form.Label>
              <InputGroup className=" mt-1">
                <InputGroup.Text className="b-none">$</InputGroup.Text>
                <Form.Control aria-label="Amount (to the nearest dollar)" />
                <InputGroup.Text>/hr</InputGroup.Text>
              </InputGroup>
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Do you Smoke ?
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">input</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Do you Drink ?
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Socially</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Do you Workout ?
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Yoga</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>
              <Form.Label htmlFor="inputPassword5" className="form-label mt-3">
                Select Bodytype
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input mt-1"
              >
                <option value="1">Slim</option>
                <option value="2">Empty</option>
                <option value="3">Empty</option>
              </Form.Select>
              <GotoHomeModal />
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
