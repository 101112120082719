import * as React  from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import fillter from '../../../Images/fillter.svg';
import { useState } from 'react';


const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];



export default function StackedBarChart() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

const toggleDropdown = () => {
  setIsDropdownOpen(!isDropdownOpen);
};

  return (
    <div className="w-100">
     <div className="dropdown mt-5 float-right">
      <button className="btn dropdown-toggle fillter-btn" type="button" onClick={toggleDropdown}>
        Filter by 
        {/* <span><img src={fillter} alt="" className="ms-3 pt-1" /></span> */}
      </button>
      <ul className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`} aria-labelledby="filterDropdown">
        <li><a className="dropdown-item" href="#">Monthly</a></li>
        <li><a className="dropdown-item" href="#">Yearly</a></li>
        <li><a className="dropdown-item" href="#">Weekly</a></li>
      </ul>
    </div>
      <BarChart
      width={500}
      height={300}
      series={[
        { data: pData, id: 'pvId', stack: 'total' },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
      
    />
    <div className="row">
    <div className='total-earning p-3 mx-2'>
      <p>Total Earning</p>
      <h5 className='pt-2'><b>$59.90</b></h5>
    </div>
    <div className='total-earning p-3 mx-2'>
      <p>Total Contract</p>
      <h5 className='pt-2'><b>250</b></h5>
    </div>
    </div>
    </div>
    
  );
}
