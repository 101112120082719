import React from "react";
import Avatar from "../../Images/Avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from './ChangePasswordModal';
import EditProfileModal from './EditProfileModal';
import ChangePhoneModal from './ChangePhoneModal';

function Overview() {
  return (
    <div className="row px-1">
      <div className="col-md-6">
        <div className="inner-shadow p-4">
          <h3 className="overview-heading">
            Overview{" "}
            <span className="float-end">
              
              <EditProfileModal />
            </span>
          </h3>
          <hr />

          <div className="d-flex">
            <img src={Avatar} alt="" className="side-bar-profile" />

            <div className="side-bar-name-nav pb-2 mx-3 pt-2">
              <h2>Eureka Seken</h2>
              <p>eureka88@email.com</p>
              <span>Licence : #45678</span>
            </div>
          </div>
          <hr />
          <div className="row ">
            <div className="col-md-5 side-nav-info">
                <p className="pt-2 pb-2">Contact phone</p>
                <p>Address</p>
            </div>
            <div className="col-md-7 side-nav-info">
                <p className="pt-2 pb-2">+62 087867654670</p>
                <p>23 Main Street, Anytown, USA 12345</p>
                <ChangePhoneModal />
            </div>

          </div>
        </div>
      </div>

      <div className="col-md-6">
      <div className="inner-shadow p-4">
          <h3 className="overview-heading">
          Signin Methods{" "}
          </h3>
          <hr />

       
            

            <div className="side-bar-name-nav pb-2 mx-3 pt-2">
             <h6 className="side-bar-name-h6">Email Address / Phone</h6>
              <p>eureka88@email.com</p>
              
            </div>
         
          <hr />
          <div className="row ">
            <div className="col-md-5 side-nav-info">
                <h6 className="pt-2 pb-2 side-bar-name-h6">Password</h6>
                <p>******************</p>
            </div>
            <div className="col-md-7 side-nav-info">
                
                <ChangePasswordModal />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
